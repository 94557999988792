import React from 'react'
import Layout from '../Layout/Layout'

const PageNotFound = () => {
    return (
        <Layout>

        <div style={{height:'400px', display:'flex',justifyContent:'center',alignItems:'center'}}>
            <h1>Page not found</h1>
        </div>
        </Layout>
    )
}

export default PageNotFound
