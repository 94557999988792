import React, { useState } from "react";
import "./Header.css";
import logo from "../../Images/Aadhyayan-logo-final.png";
import { useSelector, useDispatch } from "react-redux";
import { decrement, incrementByAmount } from "../../store/slices/navSlice";
import { useNavigate } from "react-router-dom";
import { toggleNav } from "../../store/slices/sideNavSlice";

const Header = () => {
  const navigate = useNavigate();
  // const [show, setShow] = useState(false);
  // const [showSideNav, setShowSideNav] = useState(true);
  const [ok, setOk] = useState(false);

  // const handleMenu = () => {
  //   setShow(!show);
  //   setShowSideNav(false);
  // };

  // const handleNav = () => {
  //   //  dispatch(decrement())
  //   setShowSideNav(!showSideNav);
  // };
  const dispatch = useDispatch();
  const count = useSelector((state) => state.counter.show);
  const sideNav = useSelector((state) => state.nav.hide);
  return (
    <>
      <div className="header-container">
        <div className="inner-header-container">
          <div className="one-div" onClick={() => dispatch(decrement())}>
            <img
              src={logo}
              alt=""
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </div>
          {/* {console.log(count)} */}
          {console.log(sideNav, "<===========")}
          <div className="two-div">
            <div className="div" onClick={() => dispatch(incrementByAmount())}>
              <p>
                BROWSE by <span> Course, Stream & Duration</span>{" "}
                <i class="fa-solid fa-angle-down eye"> </i>
                <div
                  className={count ? "dropdown-menu" : "dm-hide"}
                  style={{ fontFamily: "arial" }}
                >
                  <div className="dm-1">
                    <div
                      className="dm-1-1"
                      style={{ fontSize: "20px", marginTop: "-74px" }}
                    >
                      <h5 style={{ fontSize: "20px" }}>Course Type</h5>
                    </div>
                    <div className="dm-1-2">
                      <p style={{ fontSize: "15px" }}>Batchelor's </p>
                      <p style={{ fontSize: "15px" }}>Master</p>
                      <p style={{ fontSize: "15px" }}>Diploma</p>
                      <p style={{ fontSize: "15px" }}>Certificate</p>
                      <p style={{ fontSize: "15px" }}>Post Graduate</p>
                    </div>
                  </div>
                  <div
                    className="dm-2"
                    style={{ fontSize: "20px", marginTop: "-28px" }}
                  >
                    <div className="dm-1-1">
                      <h5 style={{ fontSize: "20px" }}>Stream</h5>
                    </div>
                    <div className="dm-1-2">
                      <p style={{ fontSize: "15px" }}>B.TECH</p>
                      <p style={{ fontSize: "15px" }}>BBA</p>
                      <p style={{ fontSize: "15px" }}>BCA</p>
                      <p style={{ fontSize: "15px" }}>B.COM </p>
                      <p style={{ fontSize: "15px" }}>B.PHARMA</p>
                      <p style={{ fontSize: "15px" }}>MCA</p>
                      <p style={{ fontSize: "15px" }}>MBA</p>
                      <p style={{ fontSize: "15px" }}>MA</p>
                      <p style={{ fontSize: "15px" }}>BA</p>
                    </div>
                  </div>
                  <div
                    className="dm-3"
                    style={{ fontSize: "20px", marginTop: "-30px" }}
                  >
                    <div className="dm-1-1">
                      <h5 style={{ fontSize: "20px" }}>Duration</h5>
                    </div>
                    <div
                      className="dm-1-1-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          letterSpacing: "3px",
                        }}
                      >
                        Yearly
                        <hr style={{ width: "50px", marginLeft: "0px" }} />
                      </p>
                      <p
                        style={{
                          letterSpacing: "3px",
                        }}
                      >
                        Semester
                        <hr style={{ width: "50px", marginLeft: "0px" }} />
                      </p>
                    </div>
                    <div className="dm-1-2">
                      <div className="year" style={{ marginTop: "-20px" }}>
                        <p style={{ fontSize: "15px" }}>1 Year</p>
                        <p style={{ fontSize: "15px" }}>2 Year</p>
                        <p style={{ fontSize: "15px" }}>3 Year</p>
                        <p style={{ fontSize: "15px" }}>4 Year</p>
                      </div>
                      <div
                        className="semster"
                        style={{
                          width: "150px",
                          marginTop: "-20px",
                          textAlign: "right",
                        }}
                      >
                        <p style={{ fontSize: "15px" }}>1 Semester</p>
                        <p style={{ fontSize: "15px" }}>2 Semester</p>
                        <p style={{ fontSize: "15px" }}>3 Semester</p>
                        <p style={{ fontSize: "15px" }}>4 Semester</p>
                        <p style={{ fontSize: "15px" }}>5 Semester</p>
                        <p style={{ fontSize: "15px" }}>6 Semester</p>
                        <p style={{ fontSize: "15px" }}>7 Semester</p>
                        <p style={{ fontSize: "15px" }}>8 Semester</p>
                      </div>
                    </div>
                  </div>
                </div>
              </p>
            </div>
          </div>

          <div className="three-div" onClick={() => dispatch(decrement())}>
            <div className="btn-div">
              <button className="btn">Sign in</button>
            </div>
            {sideNav ? (
              <>
                <div
                  className="burger-box "
                  // onClick={() => setShowSideNav(!showSideNav)}
                  onClick={() => dispatch(toggleNav())}
                >
                  <i
                    class="fa-solid fa-xmark"
                    data-aos="flip-left"
                    data-aos-duration="1000"
                    style={{ fontSize: "30px" }}
                    onClick={() => setOk(true)}
                  ></i>
                </div>
              </>
            ) : (
              <>
                <div
                  className="burger-box"
                  // onClick={handleNav}
                  onClick={() => dispatch(toggleNav())}
                >
                  <div
                    className="burger-line"
                    data-aos={ok && "flip-left"}
                    data-aos-duration={ok && "1000"}
                  ></div>
                  <div
                    className="burger-line-2"
                    data-aos={ok && "flip-left"}
                    data-aos-duration={ok && "1000"}
                  ></div>
                </div>
              </>
            )}
            {sideNav && (
              <div
                className="side-bar"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-easing="linear"
                data-aos-delay="100"
                // onMouseLeave={() => setShowSideNav(false)}
                style={{ zIndex: "99999999999999px" }}
              >
                <p>About US</p>
                <p>Contact</p>
                <p>Privacy Policy</p>
                <p>Help Center</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
