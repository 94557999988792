import React from "react";
import "./Footer.css";
import logo from "../../Images/Aadhyayan-logo-final.png";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const navigatePage = () => {
    navigate("/partner-1");
  };
  return (
    <>
      <div className="footer-container">
        <div className="inner-footer-container">
          <div className="one-footer-div">
            <div className="img-div">
              <img src={logo} alt="" width={"40%"} />
            </div>

            <div className="cotainer-div">
              <p style={{ cursor: "text" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla,
                velit. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Nulla, velit. Lorem ipsum dolor sit amet consectetur adipisicing
                elit. Nulla, velit. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Nulla, velit.
              </p>
            </div>

            <div className="btn-container">
              <button
                className="ft-btn"
                onClick={navigatePage}
                style={{ cursor: "pointer" }}
              >
                Become a{" "}
                <span>
                  Partner
                  {/* <i class="fa-solid fa-angle-down"></i> */}
                </span>
              </button>
              <p>
                <i class="fa-brands fa-whatsapp"></i> +91 9210663597
              </p>
            </div>
          </div>

          <div className="two-footer-div">
            <div className="one-ft-div">
              <div className="heading-ft-div">
                <p>About</p>
                <p className="p">Aadhyayan</p>
                <div className="div-s">
                  <div className="lin-div"></div>
                  <div className="lin-div-1"></div>
                </div>
              </div>
              <div className="nav-div">
                <ul>
                  <li>
                    <Link style={{ textDecoration: "none", color: "#6e6e6e" }}>
                      {" "}
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link style={{ textDecoration: "none", color: "#6e6e6e" }}>
                      {" "}
                      Vision
                    </Link>
                  </li>
                  <li>
                    <Link style={{ textDecoration: "none", color: "#6e6e6e" }}>
                      {" "}
                      Mission
                    </Link>
                  </li>
                  <li>
                    <Link style={{ textDecoration: "none", color: "#6e6e6e" }}>
                      {" "}
                      Accessibility
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      style={{ textDecoration: "none", color: "#6e6e6e" }}
                      to={"/faq"}
                    >
                      FAQs
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link style={{ textDecoration: "none", color: "#6e6e6e" }}>
                      {" "}
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="two-ft-div">
              <div className="heading-ft-div">
                {/* <p>About</p> */}
                <p className="p course">Courses</p>
                <div className="div-s">
                  <div className="lin-div"></div>
                  <div className="lin-div-1"></div>
                </div>
              </div>
              <div className="nav-div">
                <ul>
                  <li>Under Gradutation</li>
                  <li>Post Gradutation</li>
                  <li>Diploma</li>
                  <li>Certificate</li>
                  <li>Bachlors</li>
                  <li>Masters</li>
                </ul>
              </div>
            </div>
            <div className="three-ft-div">
              <div className="heading-ft-div">
                {/* <p>About</p> */}
                <p className="p">Our Group</p>
                <div className="div-s">
                  <div className="lin-div"></div>
                  <div className="lin-div-1"></div>
                </div>
              </div>
              <div className="nav-div">
                <ul>
                  <li>JNCSER Foundation</li>
                  <li>Post Gradutation</li>
                  <li>Diploma</li>
                  <li>Certificate</li>
                  <li>Bachlors</li>
                  <li>Masters</li>
                </ul>
              </div>
            </div>
            <div className="four-ft-div">
              <div className="heading-ft-div">
                {/* <p>About</p> */}
                <p className="p">Partnership</p>
                <div className="div-s">
                  <div className="lin-div"></div>
                  <div className="lin-div-1"></div>
                </div>
              </div>
              <div className="nav-div">
                <ul>
                  <li>About Partnership</li>
                  <li>Partner Login</li>
                  <li>Diploma</li>
                  <li>Certificate</li>
                  <li>Bachlors</li>
                  <li>Equalities</li>
                  <li>Blogs</li>
                  <li>Downloads</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bt-footer">
        <div className="inner-bt-ft">
          <div className="bt-ft-div">
            <div className="bt-1">
              <div className="bt-icons">
                <i class="fa-brands fa-square-facebook"></i>
                <i class="fa-brands fa-instagram"></i>
                <i class="fa-brands fa-x-twitter"></i>
                <i class="fa-brands fa-linkedin-in"></i>
              </div>{" "}
              <div className="para">Privecy | Notices/Summars | abc | abc</div>
            </div>
            <div className="bt-2">
              <p>
                Copyright &#169;2023 JNCSER Foundation. All rights reserved.
              </p>
              <p>Tearm & Condition</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
