import React from "react";
import Layout from "../Layout/Layout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import image1 from "../Images/one.jpg";
// import image1 from '../Images/two.jpg'
// import image1 from '../Images/three.jpg'
// import image1 from '../Images/four.jpg'
// import image2 from '../Images/five.jpg'
// import image2 from '../Images/six.jpg'
import image2 from "../Images/seven.jpg";
// import image3 from '../Images/eight.jpg'
import image3 from '../Images/nine.jpg'
const PartnerShipSecond = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="pr-p-2-container">
        <div className="inner-pr-p-2">
          <div className="pr-p2-div">
            <div className="inner-pr-p2-div fi">
              <h3 style={{ fontSize: "29px" }}>
                Important Note :{" "}
                <span style={{ fontWeight: "normal", fontSize: "20px" }}>
                  {" "}
                  Application before April 30, 2022, we encourage you to
                  re-apply with the necessary details. Rest assured, we will
                  reach out to you as new opportunities arise. We trust this
                  message finds you well. We would like to inform you that our
                  application process has recently undergone a comprehensive
                  revision. If you have submitted an
                </span>
              </h3>
            </div>
          </div>
          <div className="pr-p2-div bg">
            <div className="inner-pr-p2-div">
              <h3 style={{ fontSize: "40px" }}>
                Exciting Partnership Opportunities with Aadhyayan
              </h3>
              <div className="p-wrap">
                <div className="for-p">
                  <p style={{ color: "#f7e5e5", lineHeight: "30px" }}>
                    Aadhyayan (Parent Organization) is pleased to introduce the
                    Aadhyayan Sales and Marketing Partnership (ASMP) Program as
                    part of our Partnership initiative. If you possess strong
                    leadership skills, a passion for team development, and
                    expertise in marketing and sales, this opportunity is
                    tailor-made for you. Become an Aadhyayan Sales and Marketing
                    Partner (ASMP) and contribute to student success by
                    facilitating access to Study Material Subscription (SMS)
                    benefits. Here's why you should consider this opportunity:
                  </p>
                </div>
                <div className="w-image">
                  <img
                    src={image1}
                    alt=""
                    width={"70%"}
                    style={{ marginTop: "30px" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pr-p2-div ">
            <div className="inner-pr-p2-div">
              <h3 style={{ fontSize: "35px" }}>
                You bring leadership in sales; we'll bring the rest.
              </h3>
              <div className="p-wrap">
                <div className="w-image">
                  <img src={image2} alt="" width={"70%"} />
                </div>
                <div className="for-p">
                  <p style={{ color: "#630000", lineHeight: "30px" }}>
                    With low startup costs, inherent demand, and access to
                    Aadhyayan's vast sales and marketing experience, this
                    program provides a platform to establish and expand a
                    successful business in sales and marketing. Join a dynamic
                    community of Aadhyayan Sales and Marketing Partners in one
                    of the world's fastest-growing industries.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-p2-div bg">
            <div className="inner-pr-p2-div">
              <h3 style={{ fontSize: "30px" }}>
                Is this the right opportunity for you?
              </h3>
              <div className="p-wrap">
                <div className="for-p">
                  <p style={{ color: "#f7e5e5" }}>
                    If you are a customer-focused individual who excels at team
                    development in a fast-paced, ever-evolving environment,
                    becoming an Aadhyayan Sales and Marketing Partner (ASMP) is
                    an ideal prospect. As an owner, you will operate alongside
                    your Associate Partner (AP) and be fully responsible for
                    selecting and building a high-performing team, while we
                    handle the setup logistics. Your primary focus will be to
                    provide support to ensure successful sales.
                  </p>
                </div>
                <div className="w-image">
                  <img src={image3} alt="" width={'70%'}/>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-p2-div">
            <div className="inner-pr-p2-div">
              <h3 style={{ fontSize: "30px" }}>
                Great team leaders make great ASMPs.
              </h3>
              <p style={{ color: "#630000" }}>
                The key to success as an owner is engaging and leading a
                high-performing team. Owning a Sales and Marketing business
                demands strong leadership, grit, and hard work. If you possess
                these qualities, bring your leadership skills, and we will
                provide the necessary technological and operational support.
              </p>
            </div>
          </div>

          <div className="adhyayan-off bg">
            <div className="inner-ad-off">
              <div className="div-ad-off" style={{ color: "#f7e5e5" }}>
                <h3 style={{ fontSize: "30px" }}>
                  Aadhyayan is offers two types of sales and marketing
                  partnerships:
                </h3>
                <li>Managing Partnership (MP)</li>
                <li>Social Media Influencer Partnership (SMIP)</li>
              </div>
            </div>
          </div>

          <div className="ad-para-content ">
            <div className="inner-ad-para" style={{ color: "#630000" }}>
              <div className="w-ad-para">
                <p
                  style={{
                    fontWeight: "bold",
                    cursor: "text",
                    fontSize: "20px",
                  }}
                >
                  1. Managing Partnership (MP):
                  <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                    {" "}
                    (MP Code : A- (Aadhyayan) | MP001 (Managing Partner code) =
                    AMP0001)
                  </span>
                </p>
                <li style={{ marginLeft: "10px" }}>
                  Startup fee as low as ₹1,50,000 Lakhs
                </li>
                {/* <p
                  style={{
                    textAlign: "justify",
                    lineHeight: "22px",
                    letterSpacing: "1px",
                    cursor: "text",
                  }}
                >
                  Annual revenue for Managing Partner (MP) operating with 10 AP:
                  ₹47,50,000 based on modality described below. If Managing
                  Partner (MP) sell 4 Study Material Subscription (SMS) a day,
                  Study Material Subscription (SMS) fee is ₹ 2500 (Two Thousand
                  Five Hundred Rupees) But Managing Partner (MP) can offer at ₹
                  2000) by providing a Partner Code to the prospective
                  applicant. Managing Partner (MP) will get ₹ 750 (Seven Hundred
                  Fifty) on every Study Material Subscription (SMS). 4 Study
                  Material Subscription (SMS) x 365 = Annual revenue potential
                  from individual Study Material Subscription (SMS) sale * ₹
                  11,000,00 (Eleven Lac Rupees) Only Managing Partner (MP) can
                  offer Associate Partner (AP) Associate Partner (AP)
                  Registration Fee (RF): ₹ 25000 (Twenty Five Thousand)
                  Registration Fee Shall be share between Aadhyayan ₹ 10000 (Ten
                  Thousand) and Managing Partner (MP) ₹ 15,000 (Fifteen Thousand
                  Rupees). Managing Partner (MP) can make countless Associate
                  Partner (AP) under him/her. If he/she makes 10 Associate
                  Partner (AP): ₹ 15000 x 10 = ₹ 150000 (One lac fifty Thousand)
                  (*Recovered your startup cost). Managing Partner (MP) will get
                  ₹ 250 on every Study Material Subscription (SMS) of AP. If 4
                  Study Material Subscription (SMS) by Associate Partner (AP) x
                  365 = ₹ 365000 (Three Lac Sixty Five Thousand Rupees) ₹ 365000
                  (Three Lac Sixty Five Thousand Rupees) x 10 Associate Partner
                  (AP) = ₹ 3650000 (Thirty Six Lac fifty Thousand Rupees) ₹
                  3650000 (Thirty Six Lac fifty Thousand Rupees) + ₹ 110000
                  (Eleven Lac Rupees) = ₹ 4750000 (Forty Seven Lac Fifty
                  Thousand Rupees) Managing Partner (MP) Annual revenue
                  potential * ₹ 4750000 (Forty Seven Lac Fifty Thousand Rupees)*
                  Figures are projected for Managing Partner (MP) operation with
                  10 Associate Partner (AP) 4 Study Material Subscription (SMS)
                  sale every day.
                </p> */}
                <p style={{ textAlign: "justify" }}>
                  Annual revenue for Managing Partner (MP) operating with 10 AP:
                  ₹47,50,000 based on modality described below. If Managing
                  Partner (MP) sell 4 Study Material Subscription (SMS) a day,
                  Study Material Subscription (SMS) fee is ₹ 2500 (Two Thousand
                  Five Hundred Rupees) But Managing Partner (MP) can offer at ₹
                  2000) by providing a Partner Code to the prospective
                  applicant. Managing Partner (MP) will get ₹ 750 (Seven Hundred
                  Fifty) on every Study Material Subscription (SMS).
                </p>
                <p style={{ textAlign: "justify" }}>
                  4 Study Material Subscription (SMS) x 365 = Annual revenue
                  potential from individual Study Material Subscription (SMS)
                  sale * ₹ 11,000,00 (Eleven Lac Rupees) Only Managing Partner
                  (MP) can offer Associate Partner (AP) Associate Partner (AP)
                  Registration Fee (RF): ₹ 25000 (Twenty Five Thousand)
                  Registration Fee Shall be share between Aadhyayan ₹ 10000 (Ten
                  Thousand) and Managing Partner (MP) ₹ 15,000 (Fifteen Thousand
                  Rupees).
                </p>
                <p style={{ textAlign: "justify" }}>
                  Managing Partner (MP) can make countless Associate Partner
                  (AP) under him/her. If he/she makes 10 Associate Partner (AP):
                  ₹ 15000 x 10 = ₹ 150000 (One lac fifty Thousand) (*Recovered
                  your startup cost).
                </p>
                <p style={{ textAlign: "justify" }}>
                  Managing Partner (MP) will get ₹ 250 on every Study Material
                  Subscription (SMS) of AP. If 4 Study Material Subscription
                  (SMS) by Associate Partner (AP) x 365 = ₹ 365000 (Three Lac
                  Sixty Five Thousand Rupees)
                </p>
                <p style={{ textAlign: "justify" }}>
                  ₹ 365000 (Three Lac Sixty Five Thousand Rupees) x 10 Associate
                  Partner (AP) = ₹ 3650000 (Thirty Six Lac fifty Thousand
                  Rupees) ₹ 3650000 (Thirty Six Lac fifty Thousand Rupees) + ₹
                  110000 (Eleven Lac Rupees) = ₹ 4750000 (Forty Seven Lac Fifty
                  Thousand Rupees) Managing Partner (MP) Annual revenue
                  potential * ₹ 4750000 (Forty Seven Lac Fifty Thousand Rupees)
                </p>
                <p style={{ textAlign: "justify" }}>
                  * Figures are projected for Managing Partner (MP) operation
                  with 10 Associate Partner (AP) 4 Study Material Subscription
                  (SMS) sale every day.
                </p>
              </div>
            </div>
          </div>

          <div className="ad-para-con-2">
            <div className="inner-ad-p-2">
              <div className="w-inner-ad-p-2">
                <p
                  style={{
                    fontWeight: "bold",
                    cursor: "text",
                    fontSize: "20px",
                  }}
                >
                  2. Social Media Influencer Partnership (SMIP)
                  <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                    {" "}
                    Social Media Influencer Partnership (SMIP) : A- (Aadhyayan)
                    | SMIP001 (Social Media Influencer Partnership) = ASMIP001
                  </span>
                </p>
                {/* <p
                  style={{
                    textAlign: "justify",
                    lineHeight: "22px",
                    letterSpacing: "1px",
                    cursor: "text",
                  }}
                >
                  Social Media Influencer Partnership (SMIP) is a partnership
                  agreement between Aadhyayan and Influencer of any social media
                  platform with minimum following/subscriber of 10K. (Instagram
                  / Facebook / Youtube / LinkedIN / X Etc.) Social Media
                  Influencer can avail the opportunity to work with Aadhyayan as
                  a partner by registering themself on our Partner Registration
                  Link, after successful registration Influencer will get the
                  Partner Code. In the partnership you have to promote Aadhyayan
                  on your platform with your partner code. If Social Media
                  Influencer Partner (SMIP) sell 4 Study Material Subscription
                  (SMS) a day Study Material Subscription (SMS) fee is ₹ 2500
                  (Two Thousand Five Hundred Rupees) But Social Media Influencer
                  Partner (SMIP) can offer at ₹ 2000 (Two Thousand Five Hundred
                  Rupees) (follower/subscriber will use your partner code, the
                  time of submission of the application). Social Media
                  Influencer Partner (SMIP) will get ₹ 750 (Seven Hundred Fifty)
                  on every Study Material Subscription (SMS). 3. Study Material
                  Subscription (SMS) (Every day) x 365= 1460 Study Material
                  Subscription (SMS) (Yearly) = Annual revenue potential from
                  individual Study Material Subscription (SMS) sale * ₹
                  11,000,00 Approx. (Eleven Lac Rupees Approx.)
                </p> */}
                <p style={{ textAlign: "justify" }}>
                  Social Media Influencer Partnership (SMIP) is a partnership
                  agreement between Aadhyayan and Influencer of any social media
                  platform with minimum following/subscriber of 10K. (Instagram
                  / Facebook / Youtube / LinkedIN / X Etc.) Social Media
                  Influencer can avail the opportunity to work with Aadhyayan as
                  a partner by registering themself on our Partner Registration
                  Link, after successful registration Influencer will get the
                  Partner Code. In the partnership you have to promote Aadhyayan
                  on your platform with your partner code.
                </p>
                <p style={{ textAlign: "justify" }}>
                  If Social Media Influencer Partner (SMIP) sell 4 Study
                  Material Subscription (SMS) a day
                </p>
                <p style={{ textAlign: "justify" }}>
                  Study Material Subscription (SMS) fee is ₹ 2500 (Two Thousand
                  Five Hundred Rupees) But Social Media Influencer Partner
                  (SMIP) can offer at ₹ 2000 (Two Thousand Five Hundred Rupees)
                  (follower/subscriber will use your partner code, the time of
                  submission of the application).
                </p>
                <p style={{ textAlign: "justify" }}>
                  Social Media Influencer Partner (SMIP) will get ₹ 750 (Seven
                  Hundred Fifty) on every Study Material Subscription (SMS).
                </p>
                <p style={{ textAlign: "justify" }}>
                  3. Study Material Subscription (SMS) (Every day) x 365= 1460
                  Study Material Subscription (SMS) (Yearly) = Annual revenue
                  potential from individual Study Material Subscription (SMS)
                  sale * ₹ 11,000,00 Approx. (Eleven Lac Rupees Approx.)
                </p>
              </div>
            </div>
          </div>

          <div className="c-div-btn">
            <button onClick={() => navigate("/partner-3")}>
              How to get started{" "}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PartnerShipSecond;
