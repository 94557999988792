import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Testimonial.css";
import { useDispatch } from "react-redux";
import { decrement } from "../store/slices/navSlice";
const Testimonial = () => {
  const dispatch = useDispatch();
  return (
    <div className="testimonial">
      <div className="inner-testimonial" 
          onClick={() => dispatch(decrement())}>
        <div
          className="in-t-d"
          data-aos="fade-up"
          data-aos-duration="100"
          data-aos-once="true"
          data-aos-delay="100"
          style={{ marginTop: "20px" }}
        >
          <p style={{ cursor: "text" }}>TESTIMONIAL</p>
          <p className="t-p" style={{ cursor: "text" }}>
            What Our Students Saying
          </p>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={8}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
          data-aos="fade-up"
          data-aos-duration="100"
          data-aos-once="true"
          data-aos-delay="500"
        >
          <SwiperSlide className="slide">
            <div className="slid-div-1">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <div className="slid-div-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis,
              quos?
            </div>
            <div className="slid-div-3">
              <div className="sld-1">
                <div className="sld-circle"></div>
              </div>
              <div className="sld-2">
                <p>Mr P Hennery</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide">
            <div className="slid-div-1">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <div className="slid-div-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis,
              quos?
            </div>
            <div className="slid-div-3">
              <div className="sld-1">
                <div className="sld-circle"></div>
              </div>
              <div className="sld-2">
                <p>Mr A Hennery</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide">
            <div className="slid-div-1">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <div className="slid-div-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis,
              quos?
            </div>
            <div className="slid-div-3">
              <div className="sld-1">
                <div className="sld-circle"></div>
              </div>
              <div className="sld-2">
                <p>Mr S Hennery</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide">
            <div className="slid-div-1">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <div className="slid-div-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis,
              quos?
            </div>
            <div className="slid-div-3">
              <div className="sld-1">
                <div className="sld-circle"></div>
              </div>
              <div className="sld-2">
                <p>Mr N Hennery</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide">
            <div className="slid-div-1">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <div className="slid-div-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis,
              quos?
            </div>
            <div className="slid-div-3">
              <div className="sld-1">
                <div className="sld-circle"></div>
              </div>
              <div className="sld-2">
                <p>Mr C Hennery</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide">
            <div className="slid-div-1">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <div className="slid-div-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis,
              quos?
            </div>
            <div className="slid-div-3">
              <div className="sld-1">
                <div className="sld-circle"></div>
              </div>
              <div className="sld-2">
                <p>Mr P Hennery</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide">
            <div className="slid-div-1">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <div className="slid-div-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis,
              quos?
            </div>
            <div className="slid-div-3">
              <div className="sld-1">
                <div className="sld-circle"></div>
              </div>
              <div className="sld-2">
                <p>Mr O Hennery</p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonial;
