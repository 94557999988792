import { configureStore } from '@reduxjs/toolkit'
import navSlice from './slices/navSlice'
import sideNavSlice from './slices/sideNavSlice'


export const store = configureStore({
  reducer: {
    counter: navSlice,
    nav:sideNavSlice
  },
})