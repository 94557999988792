import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom/dist";

const Page = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="p-h-s">
        <div className="inner-p-h-s">
          <div className="s-ph-s">
            <h2>
              Start your business The building blocks you’ll need to become an
              Aadhyayan Sales and Marketing Partner (ASMP)
            </h2>
          </div>
        </div>
      </div>
      <div className="ph-s-2">
        <div className="inner-ph-s-2">
          <div className="width-ph-s-2">
            <p style={{ marginTop: "-40px", fontSize: "20px" }}>
              Steps you'll take
            </p>
            <p>
              From starting your application to making your first sales,
              becoming an partner can take as little as three months or as long
              as six months, depending on the availability of opportunities in
              your area.
            </p>
            <ol>
              <li> Create an account and set up your profile</li>
              <li> Fill out a formal application</li>
              <li> Application review and final interview</li>
              <li>Complete two weeks of hands-on training</li>
              <li> Set up your business and build a team </li>
              <li> Start sale.</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="ph-s-3">
        <div className="inner-ph-s-3">
          <div className="w-p-s-3" style={{ color: "#fff" }}>
            <h3 style={{ fontSize: "45px", textAlign: "center" }}>
              Ready to get started?
            </h3>
            <p style={{ cursor: "text", lineHeight: "30px" }}>
              If you have the time, dedication, and qualifications to be a{" "}
              <span style={{ fontWeight: "bold" }}>
                Aadhyayan Sales and Marketing Partner (ASMP){" "}
              </span>
              , you are only two steps away from starting your journey. First,
              determine if the location where you would like to operate is
              accepting applications through the list below. Second, create an
              account and get to work on submitting your application.
            </p>
          </div>
        </div>
      </div>
      <div className="ph-s-3 f-h">
        <div className="inner-ph-s-3">
          <div className="w-p-s-3" style={{ color: "#fff" }}>
            <h2
              style={{
                letterSpacing: "1px",
                fontSize: "35px",
                marginTop: "80px",
              }}
            >
              What you do?
            </h2>
            <h3 style={{ fontSize: "30px" }}>Set up your business</h3>
            <p
              style={{ cursor: "text", lineHeight: "30px", marginTop: "-10px" }}
            >
              You can leverage a suite of exclusive Aadhyayan negotiated deals
              to start your business, and work with our network of top-in-class
              sales and marketing service providers to keep your operation
              rolling.
            </p>
            <h3 style={{ fontSize: "30px" }}>Build your team</h3>
            <p style={{ marginTop: "-10px" }}>
              You’re a coach. This is your team. Your most important
              responsibility is recruiting and retaining solid sales and
              marketing professionals, who will enable your ongoing success.
            </p>
            <h3 style={{ fontSize: "28px" }}>
              Sales of Course Study Material (CSM) login
            </h3>
            <p style={{ marginTop: "-10px" }}>
              *Your team of sales and marketing professionals may sale at least
              4 login per day to the applicants.
            </p>
            <h3 style={{ fontSize: "28px" }}>Create your team culture</h3>
            <p style={{ marginTop: "-10px" }}>
              You lead with a can-do attitude that ensures your business
              reflects Aadhyayan’s high standards and customer-obsessed culture.
              Coach, develop, and motivate your team to exceed expectations on
              every sale.
            </p>
            <h3 style={{ fontSize: "28px" }}>Grow your business</h3>
            <p style={{ marginTop: "-10px", padding: "0px 0px 80px 0px" }}>
              Deliver a great customer experience and get the opportunity to
              hire more people, sales of more logins, and grow your business.
            </p>
          </div>
        </div>
      </div>
      <div className="ph-s-3 f-h">
        <div className="inner-ph-s-3 i-sa">
          <div className="w-p-s-3" style={{ color: "#630000" }}>
            <h2
              style={{
                textAlign: "center",
                letterSpacing: "1px",
                fontSize: "35px",
                marginTop: "60px",
              }}
            >
              What we do?
            </h2>
            <h3 style={{ fontSize: "30px" }}>Get you started</h3>
            <p
              style={{ cursor: "text", lineHeight: "30px", marginTop: "-10px" }}
            >
              *Exclusive courses study material for applicants, so that you and
              your team can market the product in the all colleges,
              universities, coaching center, student oriented potential areas
              etc. and other services help you get your sales and marketing
              business up and running.
            </p>
            <h3 style={{ fontSize: "30px" }}>Provide training</h3>
            <p style={{ marginTop: "-10px" }}>
              We provide one weeks of hands-on training to ensure you’re set up
              for success, starting with a 3 days of online training on
              introduction to Aadhyayan, followed by 4 days in the field to
              learn the tips and tricks of operating a successful sales and
              marketing business from those who know it best.
            </p>
            <h3 style={{ fontSize: "28px" }}>
              Give you a comprehensive toolkit
            </h3>
            <p style={{ marginTop: "-10px" }}>
              We give you the tools and technology you’ll need to run your
              business, designed to keep your operation running smoothly.
            </p>
            <h3 style={{ fontSize: "28px" }}>Offer on-demand support</h3>
            <p style={{ marginTop: "-10px" }}>
              Managing Partner (MP) receive ongoing support from Aadhyayan.
            </p>
            <h3 style={{ fontSize: "28px" }}>Grow your business</h3>
            <p style={{ marginTop: "-10px", padding: "0px 0px 40px 0px" }}>
              Deliver a great customer experience and get the opportunity to
              hire more people, sales of more logins, and grow your business.
            </p>
          </div>
        </div>
      </div>
      <div className="ph-s-4">
        <div className="inner-ph-s-4">
          <div className="w-ph-s-4">
            <h3 style={{ fontSize: "30px  " }}>Share our experience</h3>
            <p>
              Aadhyayan shares more than 14 years of sales and marketing
              experience to guide you in one of the fastest-growing industries
              in the world.
            </p>
            <div className="bt-div-ph">
              <h3>Your success story starts here.</h3>
              <button>Apply Now</button>
            </div>

            <p style={{ textAlign: "center" }}>
              Still have questions ? Check out our FAQs.
            </p>
            <Link to="/" className="c-h-r">
              <p>Click Here to Register</p>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Page;
