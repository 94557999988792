import React, { useEffect, useState } from "react";
import "./Home.css";
import image1 from "../Images/5.png";
import image2 from "../Images/6.png";
import image3 from "../Images/3 (2).png";
import image4 from "../Images/4 (1).png";
import image5 from "../Images/1 (1).png";
import Callback from "../Callback/Callback";
import AOS from "aos";
import "aos/dist/aos.css";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Latestnews from "../Latest news/Latestnews";
import Testimonial from "../Testimonial/Testimonial";
// import reportWebVitals from "../../reportWebVitals";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import { useDispatch } from "react-redux";
import { decrement } from "../store/slices/navSlice";
const Home = () => {
  const [show, setShow] = useState(false);
  const [rowIndex, setRowIndex] = useState(null);
  const navigate = useNavigate();
  const [data] = useState([
    {
      title: "1.What is Aadhyayan?",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
      Fusce sed commodo purus, at tempus turpis.`,
    },
    {
      title: "2.Aadhyayan publication, Books or study Material?",
      content:
        "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor.",
    },
    {
      title: "3.Getting started with Aadhyayan?",
      content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
        Fusce tempor lacus ut . Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
        `,
    },
    {
      title: "4.What is the process after the enrolment?",
      content: <p>current version is 1.2.1</p>,
    },
    {
      title: "5.When and where will examination be held?",
      content: <p>current version is 1.2.1</p>,
    },
  ]);

  const handleChange = () => {
    navigate("/searchpage");
  };
  const dispatch = useDispatch();

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Layout>
      <div className="middle-banner-container">
        <div
          className="inner-mb-container"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          data-aos-once="true"
          style={{ overflow: "hidden" }}
          onClick={() => dispatch(decrement())}
        >
          <h3>
            Absolute Course materials, Study material, Reference Material <br />
            and more
          </h3>
          <p className="in-p" style={{ cursor: "text" }}>
            Study Anyone, Anytime, Anywhere.
          </p>
          <div className="input-container">
            <input
              type="text"
              placeholder="Courses  Stream   Programmers   Vocatioinal Programmers   Duration/Mode"
              onClick={handleChange}
            />
            <button onClick={() => navigate("/searchpage")}>
              <i class="fa-solid fa-magnifying-glass"></i>Search
            </button>
          </div>
          <div className="input-container-1">
            <p style={{ cursor: "text" }}>
              Ready to study? Enter your email to create or restart your
              membership.
            </p>
            <div className="email-div fl">
              <input
                type="email"
                name=""
                id="input"
                placeholder="Email adress"
              />
              <button className="get-btn" id="btn">
                Get Started <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="home-container"
        style={{ overflowY: "hidden" }}
        onClick={() => dispatch(decrement())}
      >
        <div className="inner-home-container">
          <div
            className="home-div"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="true"
            data-aos-delay="800"
          >
            <img src={image1} alt="" width={"70%"} />
          </div>
          <div
            className="home-div-1"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <h3>Enjoy your study Material or course books online</h3>
            <p style={{ cursor: "text" }}>
              Aadhyayan emergs as a solution to this predic-ament by providing
              access to study materials for various programmers and course
              through digital platforms such as mobile devices, desk-tops,
              latops, and iPads/Tab.
            </p>
          </div>
        </div>
      </div>

      <div className="app-container">
        <div
          className="inner-app-container"
          style={{ overflowX: "hidden", overflowY: "hidden" }}
          onClick={() => dispatch(decrement())}
        >
          <div
            className="app-div-1"
            data-aos="fade-right"
            data-aos-duration="300"
            data-aos-once="true"
            data-aos-delay="100"
          >
            <h3>Download your Aadhyayan Application in your mobile phone</h3>
            <p style={{ cursor: "text" }}>
              Save your favourites easily and always have some-thing to study at
              your converience.
            </p>
          </div>
          <div
            className="app-div"
            data-aos="fade-left"
            data-aos-duration="100"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <img src={image2} alt="" width={"70%"} />
          </div>
        </div>
      </div>

      <div className="study-container">
        <div
          className="inner-study-container"
          onClick={() => dispatch(decrement())}
        >
          <div
            className="study-div"
            data-aos="fade-up"
            data-aos-duration="100"
            data-aos-once="true"
            data-aos-delay="100"
          >
            <img src={image3} alt="" width={"60%"} />
          </div>
          <div
            className=" study-div-1"
            data-aos="fade-up"
            data-aos-duration="100"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <h3>Study anytime, anyware, everywhere</h3>
            <p style={{ cursor: "text" }}>
              Majority of indian students are deprived of the course materials
              and books because of the lack flexiblities and higher costs of the
              study materials libraries are a far fetched dream for many and
              uncommon according to certian demo graphic criteria.
            </p>
          </div>
        </div>
      </div>

      <div className="family-container">
        <div
          className="inner-family-container"
          onClick={() => dispatch(decrement())}
        >
          <div
            className="family-div-1"
            data-aos="zoom-in"
            data-aos-duration="100"
            data-aos-once="true"
            data-aos-delay="100"
          >
            <h3>Become part of the Aadhyayan family.</h3>
            <p style={{ cursor: "text" }}>
              If you process strong leader skills, a passion for team
              development, and experties in marketing and sales, this
              opportunity is made for you.
            </p>
          </div>
          <div
            className="family-div"
            data-aos="zoom-in"
            data-aos-duration="100"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <img src={image4} alt="" width={"70%"} />
          </div>
        </div>
      </div>

      <div
        className="bg-container"
        data-aos-duration="1000"
        data-aos-once="true"
        data-aos="fade-up"
        data-aos-delay="100"
        onClick={() => dispatch(decrement())}
      >
        <div className="inner-bg-container"></div>
      </div>

      <div className="faq-container">
        <div
          className="inner-faq-container"
          style={{ overflowX: "hidden" }}
          onClick={() => dispatch(decrement())}
        >
          <p
            className="faq-p"
            data-aos="zoom-in"
            data-aos-duration="100"
            data-aos-once="true"
            data-aos-delay="200"
            style={{ cursor: "text" }}
          >
            <span>Frequently</span> Asked Question
          </p>
          <div className="wrap-faq">
            <div
              className="faq-img"
              data-aos="fade-left"
              data-aos-duration="100"
              data-aos-once="true"
              data-aos-delay="600"
            >
              <img src={image5} alt="" width={"70%"} />
            </div>
            <div
              className="faq-content"
              data-aos="fade-right"
              data-aos-duration="100"
              data-aos-once="true"
              data-aos-delay="1000"
            >
              {data.map((value, index) => {
                return (
                  <div className="fc-wraper">
                    <div
                      className={
                        show && rowIndex === index ? " fc-1-1" : "fc-1"
                      }
                    >
                      <p
                        onClick={() => {
                          rowIndex === index && setShow(!show);
                        }}
                      >
                        {value.title}
                      </p>
                      {show && rowIndex === index ? (
                        <i
                          class="fa-solid fa-angle-up"
                          onClick={() => {
                            setShow(false);
                          }}
                        ></i>
                      ) : (
                        <>
                          <i
                            class="fa-solid fa-angle-down"
                            onClick={() => {
                              setShow(true);
                              setRowIndex(index);
                            }}
                          ></i>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        show && rowIndex === index ? "fc-2" : "fc-hide"
                      }
                      // lang=""
                      // aria-atomic="false"
                      // // data-aos="fade-down"
                      // // data-aos-duration="1000"
                      // // // data-aos-once="true"
                      // // // data-aos-delay="100"
                    >
                      <p>{value.content}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Latestnews />

      <Testimonial />
      <Callback />

      <div className="get-start-container">
        <div
          className="inner-get-start-container"
          style={{ overflowY: "hidden" }}
          onClick={() => dispatch(decrement())}
        >
          <p
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            Ready to study? Enter your email to create or restart your
            membership.
          </p>

          <div
            className="ed-1"
            data-aos="fade-left"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <input type="email" name="" placeholder="Email adress" />
            <button className="ed-btn">
              Get Started <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
