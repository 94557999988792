import React from "react";
import Carousel from "react-multi-carousel";
import "./Latestnews.css";
import { useDispatch } from "react-redux";
import { decrement } from "../store/slices/navSlice";
const Latestnews = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const dispatch = useDispatch();
  return (
    <div className="ln-container">
      <div className="inner-ln-container" onClick={() => dispatch(decrement())}>
        <h2>Latest News</h2>
        <div className="card-div">
          <Carousel
            responsive={responsive}
            className="carousel"
            // showDots={true}
            dotListClass="custom-dot-list-style"
            //   arrows={showArrow ? false : true}
          >
            <div className="card-box">
              <img
                src="https://images.unsplash.com/photo-1588702547919-26089e690ecc?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8b25saW5lJTIwY291cnNlc3xlbnwwfHwwfHx8MA%3D%3D"
                alt=""
              />
              <div className="card-box-div">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Inventore aliquam eius laudantium ipsam animi dolore
                  laboriosam hic eos enim dignissimos.
                </p>
                <h4>April 15,2024</h4>
              </div>
            </div>
            <div className="card-box">
              <img
                src="https://images.unsplash.com/photo-1603575448360-153f093fd0b2?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjJ8fGxhcHRvcCUyMGNvZGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
              <div className="card-box-div">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Inventore aliquam eius laudantium ipsam animi dolore
                  laboriosam hic eos enim dignissimos.
                </p>
                <h4>April 15,2024</h4>
              </div>
            </div>
            <div className="card-box">
              <img
                src="https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <div className="card-box-div">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Inventore aliquam eius laudantium ipsam animi dolore
                  laboriosam hic eos enim dignissimos.
                </p>
                <h4>April 15,2024</h4>
              </div>
            </div>
            <div className="card-box">
              <img
                src="https://images.unsplash.com/photo-1588702547919-26089e690ecc?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8b25saW5lJTIwY291cnNlc3xlbnwwfHwwfHx8MA%3D%3D"
                alt=""
              />
              <div className="card-box-div">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Inventore aliquam eius laudantium ipsam animi dolore
                  laboriosam hic eos enim dignissimos.
                </p>
                <h4>April 15,2024</h4>
              </div>
            </div>
            <div className="card-box">
              <img
                src="https://images.unsplash.com/photo-1603575448360-153f093fd0b2?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjJ8fGxhcHRvcCUyMGNvZGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
              <div className="card-box-div">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Inventore aliquam eius laudantium ipsam animi dolore
                  laboriosam hic eos enim dignissimos.
                </p>
                <h4>April 15,2024</h4>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Latestnews;
