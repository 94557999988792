// import logo from './logo.svg';
import { Route, Routes } from "react-router-dom";
import "./App.css";
// import Footer from "./components/Layout/Footer/Footer";
// import Header from "./components/Layout/Header/Header";
import Home from "./components/Home/Home";
import PageNotFound from "./components/PageNotFOund/PageNotFound";
import SearchPage from "./components/SearchBox/SearchPage";
import { Toaster } from "react-hot-toast";
import BecomePartner from "./components/BecaomePartner/BecomePartner";
import Faq from "./components/Faq/Faq";
import PartnerPageFirst from "./components/PartnerPages/PartnerPageFirst";
import PartnerShipSecond from "./components/PartnerPages/PartnerShipSecond";
import Page from "./components/PartnerPages/Page";
import PartnerForm from "./components/Input-Form/PartnerForm";
function App() {
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            zIndex: "922999999",
          },
        }}
      />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/searchpage" element={<SearchPage />} />
        <Route path="/becomepartner" element={<BecomePartner />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/partner-1" element={<PartnerPageFirst />} />
        <Route path="/partner-2" element={<PartnerShipSecond />} />
        <Route path="/partner-3" element={<Page/>}/>
        <Route path="/form" element={<PartnerForm/>}/>
      </Routes>
    </>
  );
}
export default App;
