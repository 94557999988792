import React from "react";
import "./PartnerPage.css";
import Layout from "../Layout/Layout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
const PartnerPageFirst = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="p-f-container">
        <div className="inner-p-f">
          <div className="div-p-f">
            <h3>Exciting Partnership Opportunities with Aadhyayan</h3>
            <p>
              Aadhyayan (Parent Organization) is pleased to introduce the
              Aadhyayan Sales and Marketing Partner (ASMP) Program as part of
              our Partnership initiative. If you possess strong leadership
              skills, a passion for team development, and expertise in marketing
              and sales, this opportunity is tailor-made for you. Become an
              Aadhyayan Sales and Marketing Partner (ASMP) and contribute to
              student success by facilitating access to Study Material
              Subscription (SMS) benefits. Here's why you should consider this
              opportunity:
            </p>
          </div>
          <div className="div-p-f">
            <h3>You bring leadership in sales; we'll bring the rest.</h3>
            <p>
              With low startup costs, inherent demand, and access to Aadhyayan's
              vast sales and marketing experience, this program provides a
              platform to establish and expand a successful business in sales
              and marketing. Join a dynamic community of Aadhyayan Sales and
              Marketing Partners in one of the world's fastest-growing
              industries.
            </p>
          </div>
          <div className="div-p-f">
            <h3>Is this the right opportunity for you?</h3>
            <p>
              If you are a customer-focused individual who excels at team
              development in a fast-paced, ever-evolving environment, becoming
              an Aadhyayan Sales and Marketing Partner (ASMP) is an ideal
              prospect. As an owner, you will operate alongside your Associate
              Partner (AP) and be fully responsible for selecting and building a
              high-performing team, while we handle the setup logistics. Your
              primary focus will be to provide support to ensure successful
              sales.
            </p>
          </div>
          <div className="div-b-pf">
            <h3>Click here for </h3>
            <button onClick={() => navigate("/partner-2")}>
              Partnership Plan
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PartnerPageFirst;
