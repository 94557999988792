import React, { useState } from "react";
import Layout from "../Layout/Layout";
import "./Style.css";

const PartnerForm = () => {
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nationality, setNationality] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [occupation, setOccupation] = useState("");
  return (
    <Layout>
      <div className="f-1">
        {console.log(dob, email, phone)}
        <div className="inner-f-1">
          <div className="wraper-f-1">
            <h3 style={{ fontSize: "30px" }}>
              Application Form For Partnership Code
            </h3>
            <p style={{ marginTop: "-10px" }}>
              The application process includes work history and financial
              information to determine eligibility for the partnership.
            </p>
            <div className="input-wraper">
              <p>Type of partnership* : </p>
              {/* <div className="f-input-b">
                <input
                  type="radio"
                  name=""
                  id=""
                  value={"Managing Partnership"}
                />
                <label htmlFor="Managing Partnership">
                  Managing Partnership (MP)
                </label>
              </div>
              <div className="f-input-b">
                <input
                  type="radio"
                  name=""
                  id=""
                  value={"    Social Media Influencer Partnership (SMIP)"}
                />
                <label htmlFor=" Social Media Influencer Partnership ">
                  {" "}
                  Social Media Influencer Partnership (SMIP)
                </label>
              </div> */}
              <input
                type="radio"
                name="gender"
                value="Managing Partnership (MP)"
              />{" "}
              Managing Partnership (MP){" "}
              <input
                type="radio"
                name="gender"
                value=" Social Media Influencer Partnership (SMIP)"
              />{" "}
              Social Media Influencer Partnership (SMIP)
            </div>
          </div>
        </div>
      </div>

      <div className="form-container">
        <div className="inner-form-container">
          <div className="wraper-fo-c">
            <div className="div-f">
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                style={{ zIndex: "1" }}
              />
              <div
                className={name.length === 0 ? "labelline" : "labelline fix"}
              >
                Name of Applicant*
              </div>
            </div>
            <div className="div-f">
              <input
                type="text"
                onChange={(e) => setFatherName(e.target.value)}
                style={{ zIndex: "1" }}
              />
              <div
                className={
                  fatherName.length === 0 ? "labelline" : "labelline fix"
                }
              >
                Father Name*
              </div>
            </div>
            <div className="div-f">
              <input
                type="text"
                onChange={(e) => setMotherName(e.target.value)}
                style={{ zIndex: "1" }}
              />
              <div
                className={
                  motherName.length === 0 ? "labelline" : "labelline fix"
                }
              >
                Mother Name*
              </div>
            </div>
            <div className="div-f ff" style={{ zIndex: "1" }}>
              <input type="date" onChange={(e) => setDob(e.target.value)} />
              <div className="labelline fix">Date of Birth*</div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="email" onChange={(e) => setEmail(e.target.value)} />
              <div
                className={email.length === 0 ? "labelline" : "labelline fix"}
              >
                Email ID*
              </div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="tell" onChange={(e) => setPhone(e.target.value)} />
              <div
                className={phone.length === 0 ? "labelline" : "labelline fix"}
              >
                Phone No*
              </div>
            </div>

            <div className="div-f" style={{ zIndex: "1" }}>
              <input
                type="text"
                onChange={(e) => setNationality(e.target.value)}
              />
              <div
                className={
                  nationality.length === 0 ? "labelline" : "labelline fix"
                }
              >
                Nationality*
              </div>
            </div>
            <div
              className="div-g"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "0px",
                gap: "10px",
              }}
            >
              <p>Gender* :</p>
              <input type="radio" name="gender" value="male" /> Male{" "}
              <input type="radio" name="gender" value="female" /> Female
              <input type="radio" name="gender" value=" 3rd Gender" /> 3rd
              Gender
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input
                type="text"
                onChange={(e) => setNationality(e.target.value)}
              />
              <div
                className={
                  nationality.length === 0 ? "labelline" : "labelline fix"
                }
              >
                Address*
              </div>
            </div>
          </div>

          <div className="wraper-fo-c" style={{ marginTop: "15px" }}>
            {/* <div className="div-f" style={{zIndex:'1'}}>
              <input
                type="text"
                onChange={(e) => setNationality(e.target.value)}
              />
              <div
                className={
                  nationality.length === 0 ? "labelline" : "labelline fix"
                }
              >
                Address Proof* (Aadhar Card / VoterID Card / Passport/ ) Any One
              </div>
            </div> */}
            <div
              className="div-g"
              style={{
                display: "flex",
                alignItems: "center",
                // marginTop: "-30px",
                gap: "10px",
                fontSize: "12px",
              }}
            >
              <p style={{ width: "210px" }}>
                {" "}
                Address Proof* (Aadhar Card / VoterID Card / Passport/ ) Any One
              </p>
              <input
                type="file"
                name=""
                id=""
                style={{ border: "none", width: "250px" }}
              />
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input
                type="text"
                onChange={(e) => setNationality(e.target.value)}
              />
              <div
                className={
                  nationality.length === 0 ? "labelline" : "labelline fix"
                }
              >
                State*
              </div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input
                type="text"
                onChange={(e) => setNationality(e.target.value)}
              />
              <div
                className={
                  nationality.length === 0 ? "labelline" : "labelline fix"
                }
              >
                District*
              </div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input
                type="text"
                onChange={(e) => setNationality(e.target.value)}
              />
              <div
                className={
                  nationality.length === 0 ? "labelline" : "labelline fix"
                }
              >
                Nationality*
              </div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="text" onChange={(e) => setPinCode(e.target.value)} />
              <div
                className={pinCode.length === 0 ? "labelline" : "labelline fix"}
              >
                Pin Code*
              </div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="text" />
              <div className="labelline">Occupation*</div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="text" />
              <div className="labelline ">Work Experience*</div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="email" />
              <div className="labelline">Email ID*</div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
              <div className="labelline">Phone No*</div>
            </div>
          </div>
        </div>
      </div>

      <div className="bank-detail-container">
        <div className="inner-b-d-c">
          <div className="w-inner-b-d-c">
            <h3 style={{ fontSize: "28px", color: "#fff" }}>
              To facilitate the transfer of your commission, we kindly request
              you to share your bank details
            </h3>
            <h4
              style={{
                fontSize: "29px",
                marginTop: "-12px",
                letterSpacing: "1px",
                color: "#fff",
              }}
            >
              Bank Details*
            </h4>
          </div>
        </div>
      </div>
      <div className="form-container-2">
        <div className="inner-form-container-2">
          {/* <div className="f-2-heading-c">
            <p>
              To facilitate the transfer of your commission, we kindly request
              you to share your bank details
            </p>
            <p>Bank Details*</p>
          </div> */}
          <div className="wraper-fo-c-2">
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="text" />
              <div className="labelline">Bank Name*</div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="text" />
              <div className="labelline">Account Name*</div>
            </div>

            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="text" />
              <div className="labelline">Account No*</div>
            </div>
          </div>
          <div className="wraper-fo-c-2" style={{ marginTop:'-20px'}}>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="text" />
              <div className="labelline l-2">IFSC Code*</div>
            </div>
            <div className="div-f" style={{ zIndex: "1" }}>
              <input type="tel" />
              <div className="labelline l-2">UPI Code*</div>
            </div>
            <div
              className="div-f "
              style={{
                zIndex: "1",
                border: "2px solid #fff",
                borderRadius: "10px",
                width: "463px",
                height: "60px",
                // marginTop:'-20px'
              }}
            >
              <div className="labelline l-2">
                Upload Cancelled Cheque*: 
                {/* <button>Upload</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="submit-d-container">
        <div className="inner-s-d-c">
          <button>Submit</button>
        </div>
      </div>
    </Layout>
  );
};

export default PartnerForm;
