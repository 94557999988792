import React from "react";
import "./SearchPage.css";
import { useNavigate } from "react-router-dom";
const SearchPage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <div className="search-page">
      <div className="inner-search-page">
        <div className="cros-div">
          <i class="fa-solid fa-xmark" onClick={handleClick}></i>
        </div>
        <div className="input-sp-div">
          <div className="i-d">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              name=""
              id=""
              placeholder="Search Courses, Streams, Duration & more"
              autoFocus
              onChange={(e) => e.target.value}
            />
          </div>
          <p>Popular Searches</p>
          <p style={{ color: "#777777" }}>B.TECH</p>
          <hr style={{ marginLeft: "0px" }} />
          <p style={{ color: "#777777" }}>BCA</p>
          <hr style={{ marginLeft: "0px" }} />
          <p style={{ color: "#777777" }}>BBA</p>
          <hr style={{ marginLeft: "0px" }} />
          <p style={{ color: "#777777" }}>BCOM</p>
          <hr style={{ marginLeft: "0px" }} />
          <p style={{ color: "#777777" }}>MBA</p>
          <hr style={{ marginLeft: "0px" }} />
          <p style={{ color: "#777777" }}>MCA</p>
          <hr style={{ marginLeft: "0px" }} />
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
