import React, { useState } from "react";
import "./Faq.css";
import Layout from "../Layout/Layout";
import { useEffect } from "react";
const Faq = () => {
  const [show, setShow] = useState(false);
  const [rowIndex, setRowIndex] = useState(null);
  const [data] = useState([
    {
      title: "1.What is Aadhyayan?",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat, longside your Associate Partner (AP) and be fully responsible for selecting and building a high-performing team, while 
      we handle the setup logistics. Your primary focus will be to provide support to ensure successful sales.
          Fusce sed commodo purus, at tempus turpis.`,
    },
    {
      title: "2.Aadhyayan publication, Books or study Material?",
      content:
        "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor. longside your Associate Partner (AP) and be fully responsible for selecting and building a high-performing team, while we handle the setup logistics. Your primary focus will be to provide support to ensure successful sales",
    },
    {
      title: "3.Getting started with Aadhyayan?",
      content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.longside your Associate Partner (AP) and be fully responsible for selecting and building a high-performing team, while 
      we handle the setup logistics. Your primary focus will be to provide support to ensure successful sales.
            Fusce tempor lacus ut . Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
            `,
    },
    {
      title: "4.What is the process after the enrolment?",
      content: <p>current version is 1.2.1</p>,
    },
    {
      title: "5.When and where will examination be held?",
      content: <p>current version is 1.2.1</p>,
    },
  ]);
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  return (
    <Layout>
      <div className="faq-page">
        <div className="inner-faq-page">
          <div className="f-p-heading">
            <h1>Frequently Asked Questions</h1>
          </div>
          <div className="f-p-content">
            {data.map((value, index) => {
              return (
                <>
                  <div className="f-p-c-wraper">
                    <div
                      className="f-p-div-content"
                      onClick={() => {
                        setShow(!show);
                        setRowIndex(index);
                      }}
                    >
                      <h2>{value.title}</h2>
                      {show && rowIndex === index ? (
                        <i
                          class="fa-solid fa-xmark"
                          onClick={() => setShow(false)}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-plus"
                          onClick={() => {
                            setShow(true);
                            setRowIndex(index);
                          }}
                        ></i>
                      )}
                    </div>
                    <div
                      className={
                        show && rowIndex === index ? "fp-div-inner" : "fp-hide"
                      }
                    >
                      <div className="fp-inside">
                        <p>{value.content}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Faq;
